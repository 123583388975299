

















































































import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';
import TableRowActions from '@/components/Table/TableRowActions.vue';
import { ConnectionRequest } from '@/models/internal';
import { DataTableHeader } from 'vuetify';
import { Utility } from '@/tools/Utility';
import ApprovalStatusIndicator from '@/components/StatusIndicator/ApprovalStatusIndicator.vue';
import ListSelectAllAlert from '@/components/Alert/ListSelectAllAlert.vue';
import ConnectionRequestsListDetails from './ConnectionRequestsListDetails.vue';
import type { PaginationOptions } from '@/lib/interfaces';

const defaultHeaders: DataTableHeader[] = [
  { text: 'Name', value: 'name', width: 180 },
  { text: 'Phone', value: 'phone', width: 200, sortable: false },
  { text: 'Contact', value: 'contact' },
  { text: 'Address', value: 'address' },
  { text: 'City', value: 'city' },
  { text: 'On Hold', value: 'on_credit_hold', width: 100, sortable: false },
  { text: 'Auto-Approval', value: 'auto_approval_status', width: 100, sortable: false },
  // { text: 'Status', value: 'approval_status', width: 100 },
  { text: 'Actions', value: 'actions', width: 200, sortable: false, filterable: false },
];

@Component({
  name: 'ConnectionRequestsDataTable',
  components: {
    TableRowActions,
    ApprovalStatusIndicator,
    ListSelectAllAlert,
    ConnectionRequestsListDetails,
  },
})
export default class ConnectionRequestsDataTable extends Vue {
  @Prop({ required: false, default: () => defaultHeaders })
  protected readonly headers!: DataTableHeader[];

  @Prop({ required: false, default: () => [] })
  protected readonly connection_requests!: ConnectionRequest[];

  @Prop({ required: true })
  protected totalRecords!: number | string;

  @Prop({ required: false, default: '' })
  protected search!: string;

  @Prop({ required: false, default: false })
  protected showExpand!: boolean;

  @PropSync('selected', { required: false, default: () => [] })
  protected readonly selectedConnectionRequests!: ConnectionRequest[];

  @PropSync('options', { required: true })
  protected readonly syncedOptions!: PaginationOptions;

  @PropSync('allItemsSelected', { required: false, default: false })
  protected syncedAllItemsSelected!: boolean;

  protected highlightSearchText = Utility.highlightSearchText;

  protected everyItemToggle = false;

  /**
   * Workaround to access v-data-header every-item prop
   * (Could not find a way to sync header-props)
   */
  protected setEveryItemToggle(everyItem: boolean) {
    this.everyItemToggle = everyItem;
    if (!everyItem) {
      this.syncedAllItemsSelected = false;
    }
  }

  /**
   * Format the phone number
   */
  protected formatPhone(phone: string) {
    return Utility.formatPhone(phone);
  }

  protected enableOnCreditHold(connection_request: ConnectionRequest) {
    this.$emit('update-on-credit-hold', true, connection_request);
  }

  protected disableOnCreditHold(connection_request: ConnectionRequest) {
    this.$emit('update-on-credit-hold', false, connection_request);
  }
}
