var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._b({ref:"table",staticClass:"connection-requests-data-table",attrs:{"headers":_vm.headers,"items":_vm.connection_requests,"server-items-length":_vm.totalRecords,"items-per-page":_vm.syncedOptions.itemsPerPage,"page":_vm.syncedOptions.page,"sort-by":_vm.syncedOptions.sortBy,"sort-desc":_vm.syncedOptions.sortDesc,"item-key":"id","hide-default-footer":""},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.syncedOptions, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.syncedOptions, "itemsPerPage", $event)},"update:page":function($event){return _vm.$set(_vm.syncedOptions, "page", $event)},"update:sortBy":function($event){return _vm.$set(_vm.syncedOptions, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.syncedOptions, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.syncedOptions, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.syncedOptions, "sortDesc", $event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_vm._t("table-actions"),_c('list-select-all-alert',{attrs:{"records-text":"sites","selected-records":_vm.selectedConnectionRequests.length,"total-records":_vm.totalRecords,"every-item-toggle":_vm.everyItemToggle,"all-items-selected":_vm.syncedAllItemsSelected},on:{"update:allItemsSelected":function($event){_vm.syncedAllItemsSelected=$event},"update:all-items-selected":function($event){_vm.syncedAllItemsSelected=$event}}})]},proxy:true},{key:"item.name",fn:function(ref){
var name = ref.value;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.highlightSearchText(_vm.search, name))}})]}},{key:"item.phone",fn:function(ref){
var phone = ref.value;
return [_c('span',[_vm._v(_vm._s(_vm.formatPhone(phone)))])]}},{key:"item.contact",fn:function(ref){
var contact = ref.value;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.highlightSearchText(_vm.search, contact))}})]}},{key:"item.address",fn:function(ref){
var address = ref.value;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.highlightSearchText(_vm.search, address))}})]}},{key:"item.city",fn:function(ref){
var city = ref.value;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.highlightSearchText(_vm.search, city))}})]}},{key:"item.actions",fn:function(ref){
var record = ref.item;
return [_vm._t("row-actions",null,{"record":record})]}},{key:"footer",fn:function(){return [_c('v-data-footer',{attrs:{"options":_vm.syncedOptions,"show-current-page":"","show-first-last-page":"","items-per-page-options":[5, 10, 25, 50],"pagination":{
        page: _vm.syncedOptions.page,
        itemsPerPage: _vm.syncedOptions.itemsPerPage,
        pageStart: _vm.syncedOptions.itemsPerPage * (_vm.syncedOptions.page - 1),
        pageStop: _vm.syncedOptions.itemsPerPage * _vm.syncedOptions.page,
        pageCount: Math.ceil(_vm.totalRecords / _vm.syncedOptions.itemsPerPage),
        itemsLength: _vm.totalRecords
      }},on:{"update:options":function($event){_vm.syncedOptions=$event}}})]},proxy:true}],null,true),model:{value:(_vm.selectedConnectionRequests),callback:function ($$v) {_vm.selectedConnectionRequests=$$v},expression:"selectedConnectionRequests"}},'v-data-table',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }