


























import { Vue, Component, Watch } from 'vue-property-decorator';
import { ConnectionRequest } from '@/models/internal';
import { ConnectionRequestService, IConnectionRequestFindArguments } from '@/services/ConnectionRequestService';
import { DataTableHeader } from 'vuetify';
import { ITableView, PaginationOptions } from '@/lib/interfaces';
import ApprovalStatusIndicator from '@/components/StatusIndicator/ApprovalStatusIndicator.vue';
import { ApprovalStatus } from '@/lib/enum';
import { Logger } from '@/tools/Logger';
import { CollectionService, UserService } from '@/services';
// import prepareData from '@/models/functions/prepareData';
import ConnectionRequestsDataTable from './components/ConnectionRequestsDataTable.vue';
import TableRowActions from '@/components/Table/TableRowActions.vue';
import { debounce } from 'lodash';
import { renderBatchDialog } from '@/components/Dialog/BatchProcessDialog.vue';
import FileSaver from 'file-saver';
import moment from 'moment';
import ContextBarManager from '@/components/ContextBar/classes/ContextBarManager';
import { IUpdateContextBar } from '@/components/ContextBar/interfaces/UpdateContextBar.interface';

@Component({
  name: 'ConnectionRequestsList',
  components: {
    ApprovalStatusIndicator,
    ConnectionRequestsDataTable,
    TableRowActions,
  },
})
export default class ConnectionRequestsList extends Vue implements ITableView<ConnectionRequest>, IUpdateContextBar {
  public selected: ConnectionRequest[] = [];

  public headers: DataTableHeader[] = [
    { text: 'Name', value: 'customer.name', class: 'nowrap' },
    { text: 'Phone', value: 'customer.source_references.phone', width: 200, sortable: false },
    { text: 'Contact', value: 'customer.source_references.contact' },
    { text: 'Address', value: 'customer.source_references.address' },
    { text: 'City', value: 'customer.source_references.city' },
    { text: 'Status', value: 'status', width: 100, sortable: false },
    { text: 'Actions', value: 'actions', width: 200, sortable: false, filterable: false },
  ];

  public data: ConnectionRequest[] = [];

  /**
   * Debounced init function (used for limiting fetches from the search field)
   */
  public debouncedInit = debounce(this.init, 500);

  protected options: PaginationOptions = {
    page: 1,
    itemsPerPage: 10,
    sortBy: ['name'],
    sortDesc: [false],
  };

  protected loading = false;

  private readonly connectionRequestService: ConnectionRequestService = ConnectionRequestService.getInstance();

  private readonly userService: UserService = UserService.getInstance();

  private readonly collectionService: CollectionService = CollectionService.getInstance();

  private readonly logger: Logger = new Logger({ context: 'ConnectionRequestsList' });

  private totalRecords: number | string = -1;

  private allItemsSelected = false;

  private getBatchSelection = renderBatchDialog.bind(this);

  private search = '';

  private showDeniedConnectionRequests = false;

  protected get connection_requests() {
    if (this.showDeniedConnectionRequests) {
      return this.data.filter((connectionRequest) => connectionRequest.status === ApprovalStatus.Denied);
    }
    return this.data.filter((connectionRequest) => connectionRequest.status !== ApprovalStatus.Denied);
  }

  @Watch('selected')
  protected watchSelected() {
    this.updateContextBar();
  }

  @Watch('options', { deep: true, immediate: false })
  protected watchPagination() {
    this.loading = true;
    this.init();
  }

  @Watch('search')
  @Watch('distChannels')
  protected watchSearch() {
    this.loading = true;
    this.selected = [];
    this.options.page = 1;
    this.debouncedInit();
  }

  // @Watch('showOnCreditHold')
  // protected watchShowOnCreditHold() {
  //   this.loading = true;
  //   this.selected = [];
  //   this.options.page = 1;
  //   this.init();
  // }

  public created() {
    this.init();
    this.updateContextBar();
  }

  /**
   * Update the ContextBar with view-related actions
   */
  public updateContextBar() {
    const actions = [
      {
        label: 'Download CSV',
        icon: 'mdi-file-delimited-outline',
        color: 'primary',
        callback: this.downloadCsv,
      },
    ];

    ContextBarManager.setActions(...actions);
  }

  public async fetchData() {
    const sortBy = this.options.sortBy.reduce((acc, curr, idx, arr) => {
      const sortDirection = (!this.options.sortDesc[idx]) ? 'asc' : 'desc';
      const sortString = acc + `${curr}:${sortDirection}`;
      return (idx < arr.length - 1) ? sortString + ',' : sortString;
    }, '') || undefined;

    const params: IConnectionRequestFindArguments = {
      authentication_token: this.userService.getActiveToken(),
      page: this.options.page,
      per_page: this.options.itemsPerPage,
    };
    if (this.search) {
      params.name_containing = this.search;
    }
    // If the pagination has the `All` flag, delete pagination params
    if (this.options.itemsPerPage === -1) {
      params.page = 1;
      params.per_page = this.totalRecords;
    }

    if (sortBy) {
      params.sort = sortBy;
    }

    return this.connectionRequestService.api.find(params);
  }

  public async init() {
    this.loading = true;
    try {
      let connection_requests;
      let total;

      const response = await this.fetchData();

      if (typeof response === 'object' && Array.isArray(response)) {
        connection_requests = response;
      } else if (typeof response === 'object') {
        connection_requests = response.connection_requests;
        total = response.total;
      }

      if (typeof total !== 'undefined') {
        this.totalRecords = total;
      }

      this.data = connection_requests;
    } catch (error) {
      this.logger.error(error);
    } finally {
      this.loading = false;
    }
  }

  /**
   * Navigate to a summary of this ConnectionRequest's data
   * @param connectionRequest
   */
  protected async viewConnectionRequest(connectionRequest: ConnectionRequest) {
    return this.$router.push({
      name: 'connection_requests-view',
      params: {
        id: String(connectionRequest.id),
      },
    });
  }

  /**
   * Approve a ConnectionRequest record's status from the data table
   * @param connectionRequest
   */
  protected async approveConnectionRequest(connectionRequest: ConnectionRequest) {
    // this.loading.overlay = true;
    const token = this.userService.getActiveToken();
    if (!token) throw Error('Unable to get token from active User' + connectionRequest);
    // try {
    //   const response = await this.connectionRequestService.api.approve({
    //     id: connectionRequest.id,
    //     authentication_token: token,
    //     connectionRequest,
    //   });

    //   await this.connectionRequestService.update({ data: response.data.connectionRequest });
    //   const updatedConnectionRequest = this.connectionRequestService.find(response.data.connectionRequest.id);
    //   if (updatedConnectionRequest) {
    //     const i = this.data.findIndex((connectionRequest) => connectionRequest.id === updatedConnectionRequest.id);
    //     this.data[i] = updatedConnectionRequest;
    //   }

    //   return response;
    // } catch (error) {
    //   throw Error('Sorry, there was an error while saving this record.');
    // } finally {
    //   // this.loading.overlay = false;
    // }
  }

  /**
   * Deny a ConnectionRequest record's status from the data table
   * @param connectionRequest
   */
  protected async denyConnectionRequest(connectionRequest: ConnectionRequest) {
    // this.loading.overlay = true;
    const token = this.userService.getActiveToken();
    if (!token) throw Error('Unable to get token from active User' + connectionRequest);
    // try {
    //   const response = await this.connectionRequestService.api.deny({
    //     id: connectionRequest.id,
    //     authentication_token: token,
    //     connectionRequest,
    //   });

    //   await this.connectionRequestService.update({ data: response.data.connectionRequest });
    //   const updatedConnectionRequest = this.connectionRequestService.find(response.data.connectionRequest.id);
    //   if (updatedConnectionRequest) {
    //     const i = this.data.findIndex((connectionRequest) => connectionRequest.id === updatedConnectionRequest.id);
    //     this.data[i] = updatedConnectionRequest;
    //   }

    //   return response;
    // } catch (error) {
    //   throw Error('Sorry, there was an error while saving this record.');
    // } finally {
    //   // this.loading.overlay = false;
    // }
  }

  /**
   * Select all the visible ConnectionRequest rows where the status is
   * pending
   */
  protected selectPendingStatus() {
    this.selected = this.data.filter((item) => item.status === ApprovalStatus.Pending);
  }

  protected canRead(connectionRequest: any) {
    return this.$ability.can('read', connectionRequest);
  }

  protected canUpdate(connectionRequest: any) {
    return this.$ability.can('update', connectionRequest);
  }

  protected canUpdateStatus(connectionRequest: any) {
    return this.$ability.can('approve', connectionRequest);
  }

  private async downloadCsv() {
    try {
      const authenticationToken = this.userService.getActiveToken();
      const response = await this.connectionRequestService.api.download({
        authentication_token: authenticationToken,
      });
      FileSaver.saveAs(response, `vch_sites_list_${moment().format('YYYY-MM-DD_HH-mm-ss')}.csv`);
    } catch (error) {
      this.logger.error(error);
      this.$genify.alert('Error! Failed to download Site data.', 'error');
    }
  }
}
